<template>
    <v-app v-cloak :class="isMobile ? 'is-mobile' : ''">
        <nodeupSidebar />

        <v-main>
            <nodeupError :options="nodeupErrorOptions" />
            <nodeupToast />
            <slot />
        </v-main>

        <nodeupMobileDrawer :options="nodeupMobileDrawerOptions" v-if="isMobile" @hideMobileDrawer="hideMobileDrawer" />
        <v-bottom-navigation :active="isMobile" :height="56" color="white" grow tile app>
            <v-btn @click.stop="showMobileDrawer({ activeTab: 0, items: nodeupMobileDrawerOptions.itemVariants.projects() })" :class="currentActiveTab == 0 ? 'active' : ''">
                <v-icon class="mb-2">$projects</v-icon>
                <span>Projects</span>
            </v-btn>
            <v-btn @click.stop="hideMobileDrawer({ activeTab: 1 })" to="/domains" :class="currentActiveTab == 1 ? 'active' : ''">
                <v-icon class="mb-2">mdi-earth</v-icon>
                <span>Domains</span>
            </v-btn>
            <v-btn @click.stop="hideMobileDrawer({ activeTab: 2 })" to="/virtualservers" :class="currentActiveTab == 2 ? 'active' : ''">
                <v-icon class="mb-2">mdi-cloud</v-icon>
                <span class="white-space-nowrap">Web Hosting</span>
            </v-btn>
            <v-btn @click.stop="showMobileDrawer({ activeTab: 3, items: nodeupMobileDrawerOptions.itemVariants.more() })" :class="currentActiveTab == 3 ? 'active' : ''">
                <v-icon class="mb-2 v-icon-verticaldots">$verticaldots</v-icon>
                <span>More</span>
            </v-btn>
        </v-bottom-navigation>

        <formProjectAddEdit ref="refFormProjectAddEdit"></formProjectAddEdit>
        <nodeupConfirm ref="refConfirmReload"></nodeupConfirm>
    </v-app>
</template>

<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store/index';
import { useNuxtApp } from '#app';
import nodeupSidebar from '~/components/nodeupSidebar.vue';
import nodeupMobileDrawer from '~/components/nodeupMobileDrawer.vue';
import nodeupError from '~/components/nodeupError';
import nodeupToast from '~/components/nodeupToast.vue';
import nodeupConfirm from '~/components/nodeupConfirm.vue';
import formProjectAddEdit from '~/components/forms/projectAddEdit';
import Project from '@/data/models/Project';
import Team from '@/data/models/Team';

const nuxtApp = useNuxtApp();
const router = useRouter();
const store = useStore();

const refConfirmReload = ref(null);
const refFormProjectAddEdit = ref(null);
const currentActiveTab = ref(null);
const prevActiveTab = ref(null);
const nodeupErrorOptions = ref({
    type: 'global',
})
const nodeupMobileDrawerOptions = reactive({
    isVisible: false,
    items: [],
    itemVariants: {
        projects: menuProjects,
        more: menuMore,
    },
});

const isMobile = computed(() => nuxtApp.$isMobile());

watch(() => nodeupMobileDrawerOptions.isVisible, (newVal) => {
    if (!newVal) {
        restorePrevTabFocus();
    }
});

watch(() => store.hasNewVersion, (newVal) => {
    if (newVal) {
        refConfirmReload.value.open('New version available', 'Our Nodeup Cloud app has a new version available. Would you like to reload the page now?', { color: 'green' }).then((confirm) => {
            store.set({ 'hasNewVersion': false })
            if (confirm) {
                location.reload(true)
            }
        }).catch(function (e) {
            store.set({ 'hasNewVersion': false })
        })
    }
});

function menuProjects() {
    let res = [];
    let projects = Project.getItems({
        where: ['teamId', parseInt(store.team)],
        orderBy: 'name'
    });
    projects.forEach((project) => {
        res.push({
            name: project.name,
            color: project.color,
            method: () => actionSetProject(project.id),
            id: project.id,
        });
    });
    res.push({
        name: 'Add project',
        icon: 'mdi-plus',
        method: actionAddProject,
        id: null
    });
    return res;
}

function menuMore() {
    let res = [];
    res.push({
        name: 'Virtual Machines',
        icon: 'mdi-server',
        method: () => router.push('/vms/'),
    }, {
        name: 'VPN',
        icon: 'mdi-vpn',
        method: () => router.push('/vpns/'),
    });
    if (nuxtApp.$isAdmin()) {
        res.push({
            name: 'Billing',
            icon: 'mdi-invoice-list',
            method: () => router.push('/billing/'),
        });
        res.push({
            name: isPersonalTeam() ? 'My Settings' : 'Team Settings',
            icon: 'mdi-cog',
            method: () => router.push(isPersonalTeam() ? '/account' : '/settings/team'),
        });
    }
    res.push({
        name: 'Support',
        icon: 'mdi-face-agent',
        method: () => window.open('https://nodeup.io/support', '_blank'),
    });
    if (nuxtApp.$config.public.enableIntercom === 'true') {
        res.push({
            name: 'Chat',
            icon: 'mdi-chat',
            // Not needed atm as intercom binds its launch to the class btn-launch-intercom-chat
            // method: () => Intercom('show')
            method: () => { },
            class: 'btn-launch-intercom-chat',
        });
    }
    return res;
}

function isPersonalTeam() {
    const store = useStore();
    return Team.isPersonalTeam({ id: store.team });
}

function actionSetProject(id) {
    store.setProject(id);
    router.push('/projects/' + id);
}

function actionAddProject() {
    refFormProjectAddEdit.value.actionShow()
}

function showMobileDrawer(params) {
    // Clicking same item with open menu
    if (nodeupMobileDrawerOptions.isVisible && currentActiveTab.value == params.activeTab) {
        nodeupMobileDrawerOptions.isVisible = false;
        return;
    }
    prevActiveTab.value = currentActiveTab.value;
    if (params.activeTab !== 'undefined' && params.activeTab !== null) {
        currentActiveTab.value = params.activeTab;
    }
    nodeupMobileDrawerOptions.items = params.items;
    nodeupMobileDrawerOptions.isVisible = true;
}

function hideMobileDrawer(params) {
    if (params && params.activeTab) {
        currentActiveTab.value = params.activeTab;
    }
    prevActiveTab.value = null;
    nodeupMobileDrawerOptions.isVisible = false;
}

function restorePrevTabFocus() {
    if (prevActiveTab.value != null) {
        currentActiveTab.value = prevActiveTab.value;
        prevActiveTab.value = null;
    }
}
</script>

<style lang="sass">
  @use "assets/stylesheets/ordered.scss"
</style>

<style lang="scss">
.v-bottom-navigation {
    padding-top: 9px;
    padding-left: 11.5%;
    padding-right: 10%;
    box-shadow: 0px 3px 6px rgb(227 230 233 / 65%);
    z-index: 1007 !important;
    /* +1 to bottom mobile menu */

    .v-bottom-navigation__content>.v-btn {
        min-width: 60px !important;
        max-width: 120px !important;
        padding: 0 !important;
    }

    span {
        font-size: 10px;
        font-weight: 600;
    }

    .v-icon {
        max-width: 18px;
        max-height: 18px;
    }

    .v-icon-verticaldots {
        width: 5px !important;
    }

    .v-btn {
        min-width: 22%;
        max-width: 22%;
    }

    .v-btn:not(.active) {
        color: #A1A6B0 !important;
    }

    .v-btn:not(.active) path {
        fill: #A1A6B0 !important;
    }

    .v-btn.active {
        color: #175CFF !important;
    }

    .v-btn.active path {
        fill: #175CFF !important;
    }
}
</style>
