<template>
    <v-app v-cloak class="unauthenticated">

        <v-main>
            <slot />
        </v-main>

    </v-app>
</template>

<script>
export default {
    name: 'unauthenticated',
}
</script>

<style lang="sass">
  @use "assets/stylesheets/ordered.scss"
</style>

<style lang="scss">
.unauthenticated .v-container {
    max-width: 100% !important;
}

.unauthenticated #menu-container {
    display: none;
}
</style>
