
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as apikeysGSs6BF0eFXMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/account/apikeys.vue?macro=true";
import { default as indexIBtAhMsiiLMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/account/index.vue?macro=true";
import { default as sshkeysg1mCUFxaOIMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/account/sshkeys.vue?macro=true";
import { default as accountmlX9YSrRHKMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/account.vue?macro=true";
import { default as componentswpXE5W7RvJMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/apps/[id]/components.vue?macro=true";
import { default as indexVmxjeNhBhpMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/apps/[id]/index.vue?macro=true";
import { default as _91id_93TsmT7K5HxaMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/apps/[id].vue?macro=true";
import { default as indexI2gDWJhsw6Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/apps/index.vue?macro=true";
import { default as currentusagejnf8pLVuaFMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/billing/currentusage.vue?macro=true";
import { default as indexyFMUUcWo5lMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/billing/index.vue?macro=true";
import { default as ordersA0PmkQrKZbMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/billing/orders.vue?macro=true";
import { default as settingszTda9QbMb6Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/billing/settings.vue?macro=true";
import { default as billingbU6ui3BElUMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/billing.vue?macro=true";
import { default as emailw2AlzVPN8EMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/confirm/email.vue?macro=true";
import { default as indexm8qzlh0rTDMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/confirm/index.vue?macro=true";
import { default as contactssCmtXTOzrGMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/contacts.vue?macro=true";
import { default as dnsrecordsHHMZ2dsg1TMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/dnsrecords.vue?macro=true";
import { default as indexI2XwZFvBesMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/index.vue?macro=true";
import { default as nameserversvxc3C5k67AMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/nameservers.vue?macro=true";
import { default as _91id_93n1iJS81GCPMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/[id].vue?macro=true";
import { default as indexbkaB2E46TAMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/index.vue?macro=true";
import { default as newQ1n9RwF54zMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/new.vue?macro=true";
import { default as thankyouRnKF1vHkJSMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/thankyou.vue?macro=true";
import { default as forgotQKZorVLVbpMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/forgot.vue?macro=true";
import { default as googleXfoXShBmugMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/google.vue?macro=true";
import { default as indexWSUnvUlpTrMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/index.vue?macro=true";
import { default as _91id_93qlrZpBw3i8Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/invoices/[id].vue?macro=true";
import { default as login3Rbyp3w8rKMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/login.vue?macro=true";
import { default as montonio_45notifyCWr15VatPJMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/montonio-notify.vue?macro=true";
import { default as activities3zrX0OBLhLMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/activities.vue?macro=true";
import { default as indexERFbgob45nMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/index.vue?macro=true";
import { default as settingsQk9X765gXMMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/settings.vue?macro=true";
import { default as _91id_93IcGc1TBCpeMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/[id].vue?macro=true";
import { default as _0mg3e82oexZMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/0.vue?macro=true";
import { default as indexXHsyemcQToMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/index.vue?macro=true";
import { default as apinlYDLUFfPkMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/settings/api.vue?macro=true";
import { default as index1TFraJLRwbMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/settings/index.vue?macro=true";
import { default as teamWx8BR9BI37Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/settings/team.vue?macro=true";
import { default as settingspVtfylRpmDMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/settings.vue?macro=true";
import { default as signupkffDEuh49XMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/signup.vue?macro=true";
import { default as backupsQkeufevDCHMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/backups.vue?macro=true";
import { default as basicauthsn7n6KNsygAMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/basicauths.vue?macro=true";
import { default as cronjobsuo1B31S5mRMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/cronjobs.vue?macro=true";
import { default as databasesEyI87ug9pOMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/databases.vue?macro=true";
import { default as emaillistsFTqcIzImIDMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/emaillists.vue?macro=true";
import { default as emailsMNuNNX6u4KMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/emails.vue?macro=true";
import { default as ftpsbziApEYntdMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/ftps.vue?macro=true";
import { default as index98BJULjDHbMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/index.vue?macro=true";
import { default as sshCfJJBOZ84WMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/ssh.vue?macro=true";
import { default as subdomainsWZLVvb47RjMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/subdomains.vue?macro=true";
import { default as _91id_93xHj4i199pzMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id].vue?macro=true";
import { default as indexoHxpw8zg3gMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/index.vue?macro=true";
import { default as console88vlj67FYxMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/console.vue?macro=true";
import { default as indexnAyMceUnuQMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/index.vue?macro=true";
import { default as statisticsYzVRzaHbwYMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/statistics.vue?macro=true";
import { default as _91id_93IAALiv3YjcMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vms/[id].vue?macro=true";
import { default as index8dPmd9SuiQMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vms/index.vue?macro=true";
import { default as indexdOK6fc9BwIMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/index.vue?macro=true";
import { default as networksvKn4dxZXWZMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/networks.vue?macro=true";
import { default as usersJRa2yalCZ7Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/users.vue?macro=true";
import { default as _91id_93T7jqGRynR7Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id].vue?macro=true";
import { default as indexBNzKFczix9Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/vpns/index.vue?macro=true";
export default [
  {
    name: accountmlX9YSrRHKMeta?.name,
    path: "/account",
    meta: accountmlX9YSrRHKMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/account.vue"),
    children: [
  {
    name: "account-apikeys",
    path: "apikeys",
    meta: apikeysGSs6BF0eFXMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/account/apikeys.vue")
  },
  {
    name: "account",
    path: "",
    meta: indexIBtAhMsiiLMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/account/index.vue")
  },
  {
    name: "account-sshkeys",
    path: "sshkeys",
    meta: sshkeysg1mCUFxaOIMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/account/sshkeys.vue")
  }
]
  },
  {
    name: _91id_93TsmT7K5HxaMeta?.name,
    path: "/apps/:id()",
    meta: _91id_93TsmT7K5HxaMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/apps/[id].vue"),
    children: [
  {
    name: "apps-id-components",
    path: "components",
    meta: componentswpXE5W7RvJMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/apps/[id]/components.vue")
  },
  {
    name: "apps-id",
    path: "",
    meta: indexVmxjeNhBhpMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/apps/[id]/index.vue")
  }
]
  },
  {
    name: "apps",
    path: "/apps",
    meta: indexI2gDWJhsw6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/apps/index.vue")
  },
  {
    name: billingbU6ui3BElUMeta?.name,
    path: "/billing",
    meta: billingbU6ui3BElUMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/billing.vue"),
    children: [
  {
    name: "billing-currentusage",
    path: "currentusage",
    meta: currentusagejnf8pLVuaFMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/billing/currentusage.vue")
  },
  {
    name: "billing",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/billing/index.vue")
  },
  {
    name: "billing-orders",
    path: "orders",
    meta: ordersA0PmkQrKZbMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/billing/orders.vue")
  },
  {
    name: "billing-settings",
    path: "settings",
    meta: settingszTda9QbMb6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/billing/settings.vue")
  }
]
  },
  {
    name: "confirm-email",
    path: "/confirm/email",
    meta: emailw2AlzVPN8EMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/confirm/email.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: indexm8qzlh0rTDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/confirm/index.vue")
  },
  {
    name: _91id_93n1iJS81GCPMeta?.name,
    path: "/domains/:id()",
    meta: _91id_93n1iJS81GCPMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/[id].vue"),
    children: [
  {
    name: "domains-id-contacts",
    path: "contacts",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/contacts.vue")
  },
  {
    name: "domains-id-dnsrecords",
    path: "dnsrecords",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/dnsrecords.vue")
  },
  {
    name: "domains-id",
    path: "",
    meta: indexI2XwZFvBesMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/index.vue")
  },
  {
    name: "domains-id-nameservers",
    path: "nameservers",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/nameservers.vue")
  }
]
  },
  {
    name: "domains",
    path: "/domains",
    meta: indexbkaB2E46TAMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/index.vue")
  },
  {
    name: "domains-new",
    path: "/domains/new",
    meta: newQ1n9RwF54zMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/new.vue")
  },
  {
    name: "domains-thankyou",
    path: "/domains/thankyou",
    meta: thankyouRnKF1vHkJSMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/thankyou.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotQKZorVLVbpMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/forgot.vue")
  },
  {
    name: "google",
    path: "/google",
    meta: googleXfoXShBmugMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/google.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexWSUnvUlpTrMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/index.vue")
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    meta: _91id_93qlrZpBw3i8Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/invoices/[id].vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login3Rbyp3w8rKMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/login.vue")
  },
  {
    name: "montonio-notify",
    path: "/montonio-notify",
    meta: montonio_45notifyCWr15VatPJMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/montonio-notify.vue")
  },
  {
    name: _91id_93IcGc1TBCpeMeta?.name,
    path: "/projects/:id()",
    meta: _91id_93IcGc1TBCpeMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/[id].vue"),
    children: [
  {
    name: "projects-id-activities",
    path: "activities",
    meta: activities3zrX0OBLhLMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/activities.vue")
  },
  {
    name: "projects-id",
    path: "",
    meta: indexERFbgob45nMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/index.vue")
  },
  {
    name: "projects-id-settings",
    path: "settings",
    meta: settingsQk9X765gXMMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/settings.vue")
  }
]
  },
  {
    name: "projects-0",
    path: "/projects/0",
    meta: _0mg3e82oexZMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/0.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexXHsyemcQToMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/index.vue")
  },
  {
    name: settingspVtfylRpmDMeta?.name,
    path: "/settings",
    meta: settingspVtfylRpmDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/settings.vue"),
    children: [
  {
    name: "settings-api",
    path: "api",
    meta: apinlYDLUFfPkMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/settings/api.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/settings/index.vue")
  },
  {
    name: "settings-team",
    path: "team",
    meta: teamWx8BR9BI37Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/settings/team.vue")
  }
]
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupkffDEuh49XMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/signup.vue")
  },
  {
    name: _91id_93xHj4i199pzMeta?.name,
    path: "/virtualservers/:id()",
    meta: _91id_93xHj4i199pzMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id].vue"),
    children: [
  {
    name: "virtualservers-id-backups",
    path: "backups",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/backups.vue")
  },
  {
    name: "virtualservers-id-basicauths",
    path: "basicauths",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/basicauths.vue")
  },
  {
    name: "virtualservers-id-cronjobs",
    path: "cronjobs",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/cronjobs.vue")
  },
  {
    name: "virtualservers-id-databases",
    path: "databases",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/databases.vue")
  },
  {
    name: "virtualservers-id-emaillists",
    path: "emaillists",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/emaillists.vue")
  },
  {
    name: "virtualservers-id-emails",
    path: "emails",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/emails.vue")
  },
  {
    name: "virtualservers-id-ftps",
    path: "ftps",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/ftps.vue")
  },
  {
    name: "virtualservers-id",
    path: "",
    meta: index98BJULjDHbMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/index.vue")
  },
  {
    name: "virtualservers-id-ssh",
    path: "ssh",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/ssh.vue")
  },
  {
    name: "virtualservers-id-subdomains",
    path: "subdomains",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/subdomains.vue")
  }
]
  },
  {
    name: "virtualservers",
    path: "/virtualservers",
    meta: indexoHxpw8zg3gMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/index.vue")
  },
  {
    name: _91id_93IAALiv3YjcMeta?.name,
    path: "/vms/:id()",
    meta: _91id_93IAALiv3YjcMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vms/[id].vue"),
    children: [
  {
    name: "vms-id-console",
    path: "console",
    meta: console88vlj67FYxMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/console.vue")
  },
  {
    name: "vms-id",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/index.vue")
  },
  {
    name: "vms-id-statistics",
    path: "statistics",
    meta: statisticsYzVRzaHbwYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/statistics.vue")
  }
]
  },
  {
    name: "vms",
    path: "/vms",
    meta: index8dPmd9SuiQMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vms/index.vue")
  },
  {
    name: _91id_93T7jqGRynR7Meta?.name,
    path: "/vpns/:id()",
    meta: _91id_93T7jqGRynR7Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id].vue"),
    children: [
  {
    name: "vpns-id",
    path: "",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/index.vue")
  },
  {
    name: "vpns-id-networks",
    path: "networks",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/networks.vue")
  },
  {
    name: "vpns-id-users",
    path: "users",
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/users.vue")
  }
]
  },
  {
    name: "vpns",
    path: "/vpns",
    meta: indexBNzKFczix9Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vpns/index.vue")
  }
]